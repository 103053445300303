export const getMenuData = [
  {
    title: 'LU Financial Data',
    key: 'balances',
    icon: 'fe fe-home',
    // roles: ['admin'], // set user roles with access to this route
    url: '/balances',
    category: false,
  },
  {
    title: 'Cloud instances',
    key: 'service-statuses',
    icon: 'fe fe-hard-drive',
    disabled: true,
  },
  {
    title: 'Settings',
    key: 'settings',
    icon: 'fe fe-settings',
    disabled: true,
  },
]
