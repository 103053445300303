<template>
  <div :class="$style.topbar">
    <div class="mr-auto">
      <cui-search />
    </div>
    <div>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
import CuiSearch from './Search'
import CuiUserMenu from './UserMenu'

export default {
  components: {
    CuiSearch,
    CuiUserMenu,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
